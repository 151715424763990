import Api from './api.js'
import fileDownload from 'js-file-download'

const END_POINT = 'attachs'

export default {

  download( id )
  {
    return Api.post( `${END_POINT}/download`, { id }, {
      responseType: 'blob',
    }).then( response => {
      fileDownload( response, 'allegati.zip' )
      return response
    })
  },

  upload( file )
  {
    const data = new FormData()

    data.append( 'file', file )

    return Api.post( `${END_POINT}/upload`, data )
  },

  uri( id , size = '' )
  {
    let token = localStorage.getItem( 'token' )
    return `${Api.defaults.baseURL}${END_POINT}/${id}?token=${token}${ size ? '&size=' + size : '' }`
  }
}
