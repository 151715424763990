import Api from './api.js'
import useCrud from './crud.js'

const END_POINT = 'spare-parts-requests'

const { index, show, destroy, update } = useCrud(END_POINT)

export default {
  index,
  show,
  destroy,
  update,

  accept(id) {
    return Api.get(`${END_POINT}/${id}/accept`)
  },
}
