<template>
  <BaseCard
    multi-section
    :title="title"
    :subtitle="subtitle"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />

    <q-list dense class="q-pb-md">
      <template v-if="hasNewOwner">
        <q-item class="q-mx-sm q-my-xs">
          <q-item-section avatar>
            <q-icon color="pink" name="mdi-account-circle-outline" />
          </q-item-section>

          <q-item-section>
            <div class="flex items-center">
              <span>
                {{ vehicle.owner.fullname }}
                <router-link
                  title="Apri la scheda"
                  class="text-pink"
                  href="#"
                  :to="{
                    name: 'customerEdit',
                    params: { id: vehicle.owner.id },
                  }"
                >
                  <q-icon name="mdi-open-in-new" />
                </router-link>
              </span>

              <q-item-label caption class="q-ml-sm">
                <q-icon
                  name="fiber_manual_record"
                  style="font-size: 6px"
                  class="q-mr-xs"
                />
                Proprietario attuale
              </q-item-label>
            </div>
          </q-item-section>
        </q-item>

        <q-separator class="q-my-md" />
      </template>

      <template v-for="(section, name) in sections">
        <q-item :key="`user_label${name}`" class="q-mx-sm q-my-xs">
          <q-item-section avatar>
            <q-icon color="primary" :name="section.icon" />
          </q-item-section>

          <q-item-section>
            <div class="flex items-center">
              <span v-html="sectionValue(name)" />
              <q-item-label v-if="!!section.label" caption class="q-ml-sm">
                <q-icon
                  name="fiber_manual_record"
                  style="font-size: 6px"
                  class="q-mr-xs"
                />
                {{ section.label }}
              </q-item-label>
            </div>
          </q-item-section>

          <q-item-section side v-if="!!section.action">
            <BaseBtn
              @click="section.action.handle"
              :label="section.action.label"
              icon="edit"
              size="xs"
              color="pink"
            >
              <q-tooltip>
                Modifica il chilometraggio al momento delle operazioni
              </q-tooltip>
            </BaseBtn>
          </q-item-section>
        </q-item>
      </template>
    </q-list>
  </BaseCard>
</template>

<script>
import { computed } from '@vue/composition-api'
import { date } from 'quasar'

export default {
  name: 'VehicleCard',

  props: {
    vehicle: {
      type: Object,
    },

    subtitle: {
      type: String,
      default: null,
    },

    title: {
      type: String,
      default: 'Veicolo',
    },

    customer: {
      type: Object,
    },

    historyMileage: {
      type: Number,
    },
  },

  setup(props, { emit }) {
    const hasNewOwner = computed(
      () =>
        !!props.vehicle.owner &&
        !!props.customer &&
        props.customer.id != props.vehicle.owner.id
    )

    const sections = computed(() => {
      const sections = {
        brand: {
          icon: 'mdi-tag-outline',
          value() {
            return props.vehicle.model ? props.vehicle.model.brand.name : '-'
          },
        },
        model: {
          icon: 'mdi-rv-truck',
          value(value) {
            return value ? `${value.name} ${value.year}` : '-'
          },
        },
        mechanics: {
          icon: 'mdi-engine-outline',
          label: 'Meccanica',
        },
        plate_number: {
          icon: 'money',
          label: 'Targa',
        },
        serial_number: {
          icon: 'mdi-card-bulleted-outline',
          label: 'N° Serie',
        },
        frame_number: {
          icon: 'mdi-car-limousine',
          label: 'N° Telaio',
        },
        registration_date: {
          icon: 'mdi-calendar-outline',
          label: 'Data Immatricolazione',
          value(value) {
            return value ? date.formatDate(value, 'DD/MM/YYYY') : '-'
          },
        },
        mileage: {
          icon: 'mdi-gauge',
          label: 'Chilometraggio attuale',
          value(value) {
            return value ? `${value} Km` : '-'
          },
        },
      }

      if (props.historyMileage !== undefined) {
        sections.historyMileage = {
          icon: 'mdi-history',
          label: 'Chilometraggio al momento delle operazioni',
          value: () => props.historyMileage ? `${props.historyMileage} Km` : '-',
          action: {
            label: 'Modifica',
            handle: () => emit('update-mileage'),
          },
        }
      }

      return sections
    })

    function sectionValue(name) {
      if (sections.value[name].value == undefined)
        return props.vehicle[name] || '-'

      return sections.value[name].value(props.vehicle[name])
    }

    return {
      hasNewOwner,
      sections,
      sectionValue,
    }
  },
}
</script>
