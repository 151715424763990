<template>
  <div>

    <template v-for="attach in attachs">
      <q-img
        class="rounded-borders q-ma-xs"
        :key="`a-${attach.id}`"
        :src="uri(attach.id, '80x80')"
        style="height:80px;width:80px;cursor:pointer"
        @click="show(attach)"
      />
    </template>

    <AttachModal
      :attach="attach"
      @hide="attach={}" />

  </div>
</template>

<script>
import Attachs from '../../apis/attachs.js'
import AttachModal from '../attachs/AttachModal.vue';
import { ref } from '@vue/composition-api'

export default{
  name: 'AttachsList',

  components: {
    AttachModal,
  },

  props: {
    attachs: {
      type: Array,
    },
  },

  setup(){
    const { uri } = Attachs
    const attach = ref({})

    function show( item )
    {
      attach.value =  { ...item }
    }

    return {
      attach,
      show,
      uri,
    }
  },

}
</script>

