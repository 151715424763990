<template>
  <BaseCard title="Modifica informazioni">
    <div class="row col-gutter-md">
      <div class="col-12">
        <BaseCheckbox
          label="Accettata"
          v-model="sparePartRequest.accepted"
          :errors="errors"
        >
          <q-tooltip>Accettata</q-tooltip>
        </BaseCheckbox>
      </div>

      <div class="col-12">
        <BaseCheckbox
          class="q-mb-sm"
          label="Ordinata"
          v-model="sparePartRequest.ordered"
          :errors="errors"
        >
          <q-tooltip>Ordinata</q-tooltip>
        </BaseCheckbox>
      </div>

      <div class="col-12">
        <div class="flex q-gutter-md">
          <BaseDatePicker
            v-model="sparePartRequest.ordered_at"
            :errors="errors"
            style="width: 200px"
            label="Data ordine"
            clearable
            clear-icon="close"
          />

          <BaseInput
            label="Info fornitore"
            error-name="supplier_info"
            :errors="errors"
            v-model="sparePartRequest.supplier_info"
          />
        </div>
      </div>
    </div>

    <template #footer>
      <!-- default slot -->
      <slot />
    </template>
  </BaseCard>
</template>

<script>
import { date } from 'quasar'
import { watch } from '@vue/composition-api'
import useVModel from '../../hooks/vModel.js'

export default {
  name: 'SparePartRequestStatusForm',

  props: {
    value: {
      type: Object,
      required: true,
    },

    errors: {
      type: Object,
    },
  },

  setup(props, ctx) {
    const { vModel: sparePartRequest } = useVModel(props.value, ctx)

    // set date when click ordered if not already setted
    watch(
      () => props.value.ordered,
      (value) => {
        if (!value) return
        if (sparePartRequest.value.ordered_at) return

        sparePartRequest.value.ordered_at = date.formatDate(
          new Date(),
          'YYYY-MM-DD'
        )
      }
    )

    return {
      sparePartRequest,
    }
  },
}
</script>
