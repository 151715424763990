<template>
  <BaseCard
    multi-section
    :title="title"
    :subtitle="subtitle"
    v-bind="$attrs"
    v-on="$listeners">

    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />

    <q-list dense class="q-pb-md">
      <template v-for="(section,name) in sections">
        <q-item :key="`user_label${name}`" class="q-mx-sm q-my-xs">

          <q-item-section avatar>
            <q-icon color="primary" :name="section.icon" />
          </q-item-section>

          <q-item-section>
            <div class="flex items-center">
              <span v-html="sectionValue( name )" />
              <q-item-label v-if="!!section.label" caption class="q-ml-sm">
                <q-icon name="fiber_manual_record" style="font-size:6px" class="q-mr-xs" />
                {{ section.label }}
              </q-item-label>
            </div>
          </q-item-section>

        </q-item>
      </template>
    </q-list>

  </BaseCard>
</template>

<script>
export default{
  name: 'CustomerCard',

  props: {
    customer: Object,

    subtitle: {
      type: String,
      defaul: null,
    },

    title: {
      type: String,
      default: 'Cliente',
    },

  },

  setup(props){

    const sections = {
      fullname : {
        icon: 'mdi-account-circle-outline',
      },
      email: {
        icon: 'mdi-at',
        value( value ){
          return value ? `<a class="text-pink" href="mailto:${value}">${value}</a>` : '-'
        }
      },
      phone: {
        icon: 'mdi-phone-outline',
        label: 'Principale',
      },
      phone2: {
        icon: 'mdi-phone-plus-outline',
        label: 'Secondario',
      },
      fiscal_code: {
        icon: 'mdi-card-account-details-outline',
        label: 'Codice fiscale',
      },
      vat_number: {
        icon: 'mdi-card-account-details-star-outline',
        label: 'Parita IVA',
      },
      address: {
        icon: 'mdi-map-marker-outline',
        value( value ){
          let address = value.route
          if( value.zipcode ) address+= `, ${value.zipcode}`
          if( value.city ) address+= `, ${value.city}`
          if( value.distrinct ) address+= ` (${value.distrinct})`
          return address || '-'
        }
      }
    }

    function sectionValue( name )
    {
      if( sections[name].value == undefined ) return props.customer[name] || '-'

      return sections[name].value( props.customer[name] )
    }

    return {
      sections,
      sectionValue,
    }
  },

}
</script>

