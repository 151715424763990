<template>
  <div class="row q-mt-lg">
    <div class="col-12 col-md-8 q-pr-md-lg">
      <SparePartRequestCard :request="request">
        <template #footer>
          <BaseBtn
            label="Elimina"
            icon="mdi-delete-outline"
            color="negative"
            @click="destroy()"
          />

          <BaseBtn
            v-if="!request.accepted"
            label="Accetta la richiesta"
            icon="mdi-check-outline"
            color="primary"
            @click="acceptModal = true"
          />
        </template>
      </SparePartRequestCard>

      <BaseCard title="Allegati alla richiesta" v-if="request.attachs.length">
        <AttachsList :attachs="request.attachs" />
      </BaseCard>

      <SparePartRequestStatusForm v-model="editable" v-if="!!editable.id">
        <BaseBtn label="Salva" @click="$emit('save', editable)" />
      </SparePartRequestStatusForm>

      <SparePartCard
        v-if="!!request.spare_part.id"
        :spare-part="request.spare_part"
      >
        <template #footer>
          <BaseBtn label="Vai alla scheda ricambio" @click="goToSparePart" />
        </template>
      </SparePartCard>

      <BaseCard
        v-else
        title="Dettagli ricambio"
        subtitle="Nessun ricambio associato"
      >
        <template #footer>
          <BaseBtn
            icon="mdi-plus"
            label="Crea il ricambio"
            @click="goCreateSparePart"
          />
        </template>
      </BaseCard>
    </div>

    <div class="col-12 col-md-4">
      <CustomerCard :customer="request.vehicle.owner">
        <template #footer>
          <CustomerContact :customer="request.vehicle.owner" />
        </template>
      </CustomerCard>

      <VehicleCard
        :customer="request.vehicle.owner"
        :vehicle="request.vehicle"
      />

      <OperationDetailCard
        v-if="!!request.operation"
        title="Operazione collegata"
        :operation="request.operation"
      />
    </div>

    <BaseModal
      confirm
      v-model="acceptModal"
      @close="acceptModal = false"
      @confirm="accept"
      title="Conferma richiesta"
      label="Accetta"
      ko-label="Annulla"
    >
      Accettare la richiesta di ricambio selezionata?
    </BaseModal>

    <BaseModal
      confirm
      v-model="detailDestroy"
      @close="show()"
      @confirm="$emit('destroy')"
      title="Conferma richiesta"
      label="Elimina"
      ko-label="Annulla"
    >
      Rimuovere la richiesta di ricambio selezionata?
    </BaseModal>
  </div>
</template>

<script>
import { ref, toRefs } from '@vue/composition-api'
import SparePartsRequests from '../../apis/sparePartsRequests'
import useApiCrudTable from '../../hooks/apiCrudTable'
import VehicleCard from '../vehicles/VehicleCard.vue'
import SparePartRequestCard from './SparePartRequestCard.vue'
import OperationDetailCard from '../operations/DetailCard.vue'
import AttachsList from '../attachs/AttachsList.vue'
import SparePartCard from '../spare-parts/SparePartCard.vue'
import CustomerCard from '../customers/CustomerCard.vue'
import SparePartRequestStatusForm from './SparePartRequestStatusForm.vue'
import CustomerContact from '../customers/CustomerContact.vue'

export default {
  name: 'SparePartRequestShow',

  components: {
    OperationDetailCard,
    SparePartRequestCard,
    VehicleCard,
    AttachsList,
    SparePartCard,
    CustomerCard,
    SparePartRequestStatusForm,
    CustomerContact,
  },

  props: {
    request: {
      type: Object,
    },
  },

  setup(props, ctx) {
    const { destroy, state, show } = useApiCrudTable({
      api: SparePartsRequests,
      ctx,
      routes: {
        destroy: 'sparePartRequestDestroy',
        show: 'sparePartRequestShow',
      },
    })

    const acceptModal = ref(false)
    function accept() {
      acceptModal.value = false
      ctx.emit('accept')
    }

    function goToSparePart() {
      const id = props.request.spare_part.id
      ctx.root.$router.push({ name: 'sparePartShow', params: { id } })
    }

    function goCreateSparePart() {
      ctx.root.$router.push({
        name: 'sparePartCreate',
        query: { from_request: props.request.id },
      })
    }

    return {
      accept,
      acceptModal,
      destroy,
      goToSparePart,
      goCreateSparePart,
      show,
      ...toRefs(state),
    }
  },
}
</script>
