<template>
  <BasePage
    padding
    title="Richiesta ricambi"
    subtitle="gestione richiesta dagli operatori"
    :breadcrumb="$route.matched"
  >
    <!-- detail -->
    <SparePartRequestShow
      v-if="detailShow"
      :request="detail"
      @destroy="apiDestroy(detail.id)"
      @accept="accept"
      @save="
        (value) => {
          editable = { ...value }
          save()
        }
      "
    />

    <!-- table -->
    <SparePartsRequestsTable
      v-else
      :data="data"
      :loading="loading"
      :pagination.sync="pagination"
      :filters="filters"
      @filter="(newFilters) => (filters = newFilters)"
      @row-click="(_, row) => show(row.id)"
      @request="onRequest"
    />
  </BasePage>
</template>

<script>
import SparePartRequestShow from '../components/spare-parts-requests/SparePartRequestShow.vue'
import SparePartsRequestsTable from '../components/spare-parts-requests/SparePartsRequestsTable.vue'
import useApiCrudTable from '../hooks/apiCrudTable.js'
import { toRefs } from '@vue/composition-api'
import useNotify from '../hooks/notify.js'
import SparePartsRequests from '../apis/sparePartsRequests'

export default {
  name: 'SpareParts',

  components: {
    SparePartRequestShow,
    SparePartsRequestsTable,
  },

  setup(_, ctx) {
    const {
      close,
      errors,
      loading,
      onRequest,
      state,
      show,
      edit,
      destroy,
      apiDestroy,
      save,
      setDetail,
    } = useApiCrudTable({
      api: SparePartsRequests,
      ctx,
      events: {
        destroy(response) {
          const index = state.data.findIndex(
            (item) => item.id == state.detail.id
          )
          state.data.splice(index, 1)

          success('Eliminazione completata')
          return response
        },
        update(response) {
          success('Modifiche registrate')

          //update index if exists
          const index = state.data.findIndex(
            (item) => item.id == state.detail.id
          )
          if (index > -1) state.data.slice(index, 1, { ...response.data })

          return response
        },
      },
      routes: {
        destroy: 'sparePartRequestDestroy',
        index: 'sparePartsRequests',
        show: 'sparePartRequestShow',
      },
      filters: {
        sort: 'id',
        dir: 'desc',
        query: '',
      },
    })

    const { success } = useNotify()

    async function accept() {
      const data = await SparePartsRequests.accept(state.detail.id)
      setDetail(data.data)
    }

    return {
      accept,
      apiDestroy,
      close,
      destroy,
      edit,
      errors,
      loading,
      onRequest,
      save,
      show,
      ...toRefs(state),
    }
  },
}
</script>
