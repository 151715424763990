<template>
  <q-table
    class="wrapped"
    flat
    square
    binary-state-sort
    v-bind="$attrs"
    v-on="$listeners"
    :columns="columns"
  >
    <template #top>
      <SparePartsRequestsListFilters
        :value="filters"
        @input="(filters) => $emit('filter', filters)"
      />
    </template>

    <template v-slot:body="props">
      <q-tr
        @click="$emit('row-click', $event, props.row)"
        class="cursor-pointer"
      >
        <q-td v-for="col in props.cols" :key="col.name" :props="props">
          <q-icon
            v-if="col.name == 'accepted'"
            size="sm"
            :name="`mdi-${
              col.value ? 'checkbox-marked-circle-outline' : 'radiobox-blank'
            }`"
            color="grey-1"
          >
            <q-tooltip v-if="col.value">Accettata</q-tooltip>
          </q-icon>

          <q-icon
            v-else-if="col.name == 'ordered'"
            size="sm"
            :name="`mdi-${
              col.value ? 'checkbox-marked-circle-outline' : 'radiobox-blank'
            }`"
            color="grey-1"
          >
            <q-tooltip v-if="col.value">Ordinata</q-tooltip>
          </q-icon>

          <template v-else>
            {{ col.value }}
          </template>
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>

<script>
import { date } from 'quasar'
import SparePartsRequestsListFilters from './SparePartsRequestsListFilters.vue'

export default {
  name: 'SparePartsRequetsTable',

  components: {
    SparePartsRequestsListFilters,
  },

  props: {
    filters: {
      type: Object,
    },
  },

  setup() {
    const columns = [
      {
        name: 'id',
        label: 'ID',
        field: 'id',
        align: 'left',
        style: 'width:38px',
      },
      {
        name: 'accepted',
        label: 'Accettata',
        align: 'center',
        field: 'accepted',
        sortable: true,
        style: 'width:38px',
      },
      {
        name: 'ordered',
        label: 'Ordinata',
        align: 'center',
        field: 'ordered',
        sortable: true,
        style: 'width:38px',
      },
      {
        name: 'customer',
        label: 'Cliente',
        align: 'left',
        field: (row) => (row.vehicle ? `${row.vehicle?.owner?.fullname}` : '-'),
        style: 'width:80px',
      },
      {
        name: 'vehicle',
        label: 'Veicolo',
        align: 'left',
        field: (row) =>
          row.vehicle
            ? `${row.vehicle.model.brand.name} - ${row.vehicle.model.name} ${row.vehicle.model.year}`
            : '-',
        style: 'width:80px',
      },
      {
        name: 'message',
        label: 'Richiesta',
        field: 'message',
        align: 'left',
      },
      {
        name: 'created_at',
        label: 'Creata il',
        align: 'left',
        field: 'created_at',
        sortable: true,
        format: (value) => date.formatDate(value, 'DD/MM/YYYY, HH:mm'),
        style: 'width:80px',
      },
    ]

    return {
      columns,
    }
  },
}
</script>
