<template>
  <BaseCard multi-section :title="title">
    <q-list dense class="q-pb-md">
      <template v-for="(section, name) in sections">
        <q-item :key="`op_label${name}`" class="q-mx-sm q-my-xs">
          <q-item-section avatar>
            <q-icon color="primary" :name="section.icon" />
          </q-item-section>

          <q-item-section>
            <div class="flex items-center">
              <span v-html="sectionValue(name)" />
              <q-item-label v-if="!!section.label" caption class="q-ml-sm">
                <q-icon
                  name="fiber_manual_record"
                  style="font-size: 6px"
                  class="q-mr-xs"
                />
                {{ section.label }}
              </q-item-label>
            </div>
          </q-item-section>
        </q-item>
      </template>
    </q-list>

    <template #footer>
      <slot />
    </template>
  </BaseCard>
</template>

<script>
import { date } from 'quasar'

export default {
  name: 'DetailCard',

  props: {
    title: {
      type: String,
      default: 'Dettaglio operazione',
    },

    operation: {
      type: Object,
    },
  },

  setup(props) {
    const sections = {
      type: {
        icon: 'mdi-tag-outline',
        value: (value) => value.name,
        label: 'Tipologia',
      },
      user: {
        icon: 'mdi-tools',
        value: (value) => value.name,
        label: 'Operatore incaricato',
      },
      created_at: {
        icon: 'mdi-calendar',
        value: (value) =>
          value ? date.formatDate(value, 'DD/MM/YYYY, HH:mm') : '-',
        label: 'Data inserimento',
      },
      creator: {
        icon: 'mdi-account-outline',
        value: (value) => value.name,
        label: 'Operatore',
      },
      duration: {
        icon: 'mdi-timer-outline',
        value: (value) => `${value * 1} ore`,
        label: 'Tempo impiegato',
      },
    }

    function sectionValue(name) {
      if (sections[name].value == undefined) return props.operation[name] || '-'

      return sections[name].value(props.operation[name] || {})
    }

    return {
      sections,
      sectionValue,
    }
  },
}
</script>
