<template>

  <BaseModal
    :persistent="false"
    :value="show"
    no-actions
    close-btn
    @hide="$emit('hide')"
    :title="attach.name">

    <div class="text-center">
      <q-img
        contain
        :src="uri( attach.id )"
        :style="style"
      />
    </div>

  </BaseModal>

</template>

<script>
import Attachs from '../../apis/attachs.js'
import { computed } from '@vue/composition-api'

export default{

  props: {
    attach: {
      type: Object,
    }
  },

  setup(props){
    const show = computed( () => !!props.attach.id )
    const { uri } = Attachs

    const style = computed(() => {
      if( props.attach.image_size == undefined  ) return {}

      const { width, height } = props.attach.image_size

      return { width: `${width}px`, height: `${height}px` }
    })

    return {
      show,
      style,
      uri,
    }
  },
}
</script>

